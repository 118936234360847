/**
 * @fileoverview gRPC-Web generated client stub for order_comment
 * @enhanceable
 * @public
 */

// Code generated by protoc-gen-grpc-web. DO NOT EDIT.
// versions:
// 	protoc-gen-grpc-web v1.4.2
// 	protoc              v3.21.12
// source: protobuf/order_comment/order_comment.proto


/* eslint-disable */
// @ts-nocheck


import * as grpcWeb from 'grpc-web';

import * as protobuf_order_comment_order_comment_pb from '../../protobuf/order_comment/order_comment_pb';


export class OrderCommentServiceClient {
  client_: grpcWeb.AbstractClientBase;
  hostname_: string;
  credentials_: null | { [index: string]: string; };
  options_: null | { [index: string]: any; };

  constructor (hostname: string,
               credentials?: null | { [index: string]: string; },
               options?: null | { [index: string]: any; }) {
    if (!options) options = {};
    if (!credentials) credentials = {};
    options['format'] = 'binary';

    this.client_ = new grpcWeb.GrpcWebClientBase(options);
    this.hostname_ = hostname.replace(/\/+$/, '');
    this.credentials_ = credentials;
    this.options_ = options;
  }

  methodDescriptorCreate = new grpcWeb.MethodDescriptor(
    '/order_comment.OrderCommentService/Create',
    grpcWeb.MethodType.UNARY,
    protobuf_order_comment_order_comment_pb.CreateOrderCommentRequest,
    protobuf_order_comment_order_comment_pb.OrderCommentResponse,
    (request: protobuf_order_comment_order_comment_pb.CreateOrderCommentRequest) => {
      return request.serializeBinary();
    },
    protobuf_order_comment_order_comment_pb.OrderCommentResponse.deserializeBinary
  );

  create(
    request: protobuf_order_comment_order_comment_pb.CreateOrderCommentRequest,
    metadata: grpcWeb.Metadata | null): Promise<protobuf_order_comment_order_comment_pb.OrderCommentResponse>;

  create(
    request: protobuf_order_comment_order_comment_pb.CreateOrderCommentRequest,
    metadata: grpcWeb.Metadata | null,
    callback: (err: grpcWeb.RpcError,
               response: protobuf_order_comment_order_comment_pb.OrderCommentResponse) => void): grpcWeb.ClientReadableStream<protobuf_order_comment_order_comment_pb.OrderCommentResponse>;

  create(
    request: protobuf_order_comment_order_comment_pb.CreateOrderCommentRequest,
    metadata: grpcWeb.Metadata | null,
    callback?: (err: grpcWeb.RpcError,
               response: protobuf_order_comment_order_comment_pb.OrderCommentResponse) => void) {
    if (callback !== undefined) {
      return this.client_.rpcCall(
        this.hostname_ +
          '/order_comment.OrderCommentService/Create',
        request,
        metadata || {},
        this.methodDescriptorCreate,
        callback);
    }
    return this.client_.unaryCall(
    this.hostname_ +
      '/order_comment.OrderCommentService/Create',
    request,
    metadata || {},
    this.methodDescriptorCreate);
  }

  methodDescriptorUpdate = new grpcWeb.MethodDescriptor(
    '/order_comment.OrderCommentService/Update',
    grpcWeb.MethodType.UNARY,
    protobuf_order_comment_order_comment_pb.UpdateOrderCommentRequest,
    protobuf_order_comment_order_comment_pb.OrderCommentResponse,
    (request: protobuf_order_comment_order_comment_pb.UpdateOrderCommentRequest) => {
      return request.serializeBinary();
    },
    protobuf_order_comment_order_comment_pb.OrderCommentResponse.deserializeBinary
  );

  update(
    request: protobuf_order_comment_order_comment_pb.UpdateOrderCommentRequest,
    metadata: grpcWeb.Metadata | null): Promise<protobuf_order_comment_order_comment_pb.OrderCommentResponse>;

  update(
    request: protobuf_order_comment_order_comment_pb.UpdateOrderCommentRequest,
    metadata: grpcWeb.Metadata | null,
    callback: (err: grpcWeb.RpcError,
               response: protobuf_order_comment_order_comment_pb.OrderCommentResponse) => void): grpcWeb.ClientReadableStream<protobuf_order_comment_order_comment_pb.OrderCommentResponse>;

  update(
    request: protobuf_order_comment_order_comment_pb.UpdateOrderCommentRequest,
    metadata: grpcWeb.Metadata | null,
    callback?: (err: grpcWeb.RpcError,
               response: protobuf_order_comment_order_comment_pb.OrderCommentResponse) => void) {
    if (callback !== undefined) {
      return this.client_.rpcCall(
        this.hostname_ +
          '/order_comment.OrderCommentService/Update',
        request,
        metadata || {},
        this.methodDescriptorUpdate,
        callback);
    }
    return this.client_.unaryCall(
    this.hostname_ +
      '/order_comment.OrderCommentService/Update',
    request,
    metadata || {},
    this.methodDescriptorUpdate);
  }

  methodDescriptorGet = new grpcWeb.MethodDescriptor(
    '/order_comment.OrderCommentService/Get',
    grpcWeb.MethodType.UNARY,
    protobuf_order_comment_order_comment_pb.GetOrderCommentsRequest,
    protobuf_order_comment_order_comment_pb.GetOrderCommentsResponse,
    (request: protobuf_order_comment_order_comment_pb.GetOrderCommentsRequest) => {
      return request.serializeBinary();
    },
    protobuf_order_comment_order_comment_pb.GetOrderCommentsResponse.deserializeBinary
  );

  get(
    request: protobuf_order_comment_order_comment_pb.GetOrderCommentsRequest,
    metadata: grpcWeb.Metadata | null): Promise<protobuf_order_comment_order_comment_pb.GetOrderCommentsResponse>;

  get(
    request: protobuf_order_comment_order_comment_pb.GetOrderCommentsRequest,
    metadata: grpcWeb.Metadata | null,
    callback: (err: grpcWeb.RpcError,
               response: protobuf_order_comment_order_comment_pb.GetOrderCommentsResponse) => void): grpcWeb.ClientReadableStream<protobuf_order_comment_order_comment_pb.GetOrderCommentsResponse>;

  get(
    request: protobuf_order_comment_order_comment_pb.GetOrderCommentsRequest,
    metadata: grpcWeb.Metadata | null,
    callback?: (err: grpcWeb.RpcError,
               response: protobuf_order_comment_order_comment_pb.GetOrderCommentsResponse) => void) {
    if (callback !== undefined) {
      return this.client_.rpcCall(
        this.hostname_ +
          '/order_comment.OrderCommentService/Get',
        request,
        metadata || {},
        this.methodDescriptorGet,
        callback);
    }
    return this.client_.unaryCall(
    this.hostname_ +
      '/order_comment.OrderCommentService/Get',
    request,
    metadata || {},
    this.methodDescriptorGet);
  }

  methodDescriptorDelete = new grpcWeb.MethodDescriptor(
    '/order_comment.OrderCommentService/Delete',
    grpcWeb.MethodType.UNARY,
    protobuf_order_comment_order_comment_pb.DeleteOrderCommentsRequest,
    protobuf_order_comment_order_comment_pb.DeleteOrderCommentsResponse,
    (request: protobuf_order_comment_order_comment_pb.DeleteOrderCommentsRequest) => {
      return request.serializeBinary();
    },
    protobuf_order_comment_order_comment_pb.DeleteOrderCommentsResponse.deserializeBinary
  );

  delete(
    request: protobuf_order_comment_order_comment_pb.DeleteOrderCommentsRequest,
    metadata: grpcWeb.Metadata | null): Promise<protobuf_order_comment_order_comment_pb.DeleteOrderCommentsResponse>;

  delete(
    request: protobuf_order_comment_order_comment_pb.DeleteOrderCommentsRequest,
    metadata: grpcWeb.Metadata | null,
    callback: (err: grpcWeb.RpcError,
               response: protobuf_order_comment_order_comment_pb.DeleteOrderCommentsResponse) => void): grpcWeb.ClientReadableStream<protobuf_order_comment_order_comment_pb.DeleteOrderCommentsResponse>;

  delete(
    request: protobuf_order_comment_order_comment_pb.DeleteOrderCommentsRequest,
    metadata: grpcWeb.Metadata | null,
    callback?: (err: grpcWeb.RpcError,
               response: protobuf_order_comment_order_comment_pb.DeleteOrderCommentsResponse) => void) {
    if (callback !== undefined) {
      return this.client_.rpcCall(
        this.hostname_ +
          '/order_comment.OrderCommentService/Delete',
        request,
        metadata || {},
        this.methodDescriptorDelete,
        callback);
    }
    return this.client_.unaryCall(
    this.hostname_ +
      '/order_comment.OrderCommentService/Delete',
    request,
    metadata || {},
    this.methodDescriptorDelete);
  }

  methodDescriptorList = new grpcWeb.MethodDescriptor(
    '/order_comment.OrderCommentService/List',
    grpcWeb.MethodType.UNARY,
    protobuf_order_comment_order_comment_pb.ListOrderCommentsRequest,
    protobuf_order_comment_order_comment_pb.ListOrderCommentsResponse,
    (request: protobuf_order_comment_order_comment_pb.ListOrderCommentsRequest) => {
      return request.serializeBinary();
    },
    protobuf_order_comment_order_comment_pb.ListOrderCommentsResponse.deserializeBinary
  );

  list(
    request: protobuf_order_comment_order_comment_pb.ListOrderCommentsRequest,
    metadata: grpcWeb.Metadata | null): Promise<protobuf_order_comment_order_comment_pb.ListOrderCommentsResponse>;

  list(
    request: protobuf_order_comment_order_comment_pb.ListOrderCommentsRequest,
    metadata: grpcWeb.Metadata | null,
    callback: (err: grpcWeb.RpcError,
               response: protobuf_order_comment_order_comment_pb.ListOrderCommentsResponse) => void): grpcWeb.ClientReadableStream<protobuf_order_comment_order_comment_pb.ListOrderCommentsResponse>;

  list(
    request: protobuf_order_comment_order_comment_pb.ListOrderCommentsRequest,
    metadata: grpcWeb.Metadata | null,
    callback?: (err: grpcWeb.RpcError,
               response: protobuf_order_comment_order_comment_pb.ListOrderCommentsResponse) => void) {
    if (callback !== undefined) {
      return this.client_.rpcCall(
        this.hostname_ +
          '/order_comment.OrderCommentService/List',
        request,
        metadata || {},
        this.methodDescriptorList,
        callback);
    }
    return this.client_.unaryCall(
    this.hostname_ +
      '/order_comment.OrderCommentService/List',
    request,
    metadata || {},
    this.methodDescriptorList);
  }

  methodDescriptorListAll = new grpcWeb.MethodDescriptor(
    '/order_comment.OrderCommentService/ListAll',
    grpcWeb.MethodType.UNARY,
    protobuf_order_comment_order_comment_pb.ListOrderCommentsRequest,
    protobuf_order_comment_order_comment_pb.ListOrderCommentsResponse,
    (request: protobuf_order_comment_order_comment_pb.ListOrderCommentsRequest) => {
      return request.serializeBinary();
    },
    protobuf_order_comment_order_comment_pb.ListOrderCommentsResponse.deserializeBinary
  );

  listAll(
    request: protobuf_order_comment_order_comment_pb.ListOrderCommentsRequest,
    metadata: grpcWeb.Metadata | null): Promise<protobuf_order_comment_order_comment_pb.ListOrderCommentsResponse>;

  listAll(
    request: protobuf_order_comment_order_comment_pb.ListOrderCommentsRequest,
    metadata: grpcWeb.Metadata | null,
    callback: (err: grpcWeb.RpcError,
               response: protobuf_order_comment_order_comment_pb.ListOrderCommentsResponse) => void): grpcWeb.ClientReadableStream<protobuf_order_comment_order_comment_pb.ListOrderCommentsResponse>;

  listAll(
    request: protobuf_order_comment_order_comment_pb.ListOrderCommentsRequest,
    metadata: grpcWeb.Metadata | null,
    callback?: (err: grpcWeb.RpcError,
               response: protobuf_order_comment_order_comment_pb.ListOrderCommentsResponse) => void) {
    if (callback !== undefined) {
      return this.client_.rpcCall(
        this.hostname_ +
          '/order_comment.OrderCommentService/ListAll',
        request,
        metadata || {},
        this.methodDescriptorListAll,
        callback);
    }
    return this.client_.unaryCall(
    this.hostname_ +
      '/order_comment.OrderCommentService/ListAll',
    request,
    metadata || {},
    this.methodDescriptorListAll);
  }

  methodDescriptorExport = new grpcWeb.MethodDescriptor(
    '/order_comment.OrderCommentService/Export',
    grpcWeb.MethodType.UNARY,
    protobuf_order_comment_order_comment_pb.ExportOrderCommentsRequest,
    protobuf_order_comment_order_comment_pb.ExportOrderCommentsResponse,
    (request: protobuf_order_comment_order_comment_pb.ExportOrderCommentsRequest) => {
      return request.serializeBinary();
    },
    protobuf_order_comment_order_comment_pb.ExportOrderCommentsResponse.deserializeBinary
  );

  export(
    request: protobuf_order_comment_order_comment_pb.ExportOrderCommentsRequest,
    metadata: grpcWeb.Metadata | null): Promise<protobuf_order_comment_order_comment_pb.ExportOrderCommentsResponse>;

  export(
    request: protobuf_order_comment_order_comment_pb.ExportOrderCommentsRequest,
    metadata: grpcWeb.Metadata | null,
    callback: (err: grpcWeb.RpcError,
               response: protobuf_order_comment_order_comment_pb.ExportOrderCommentsResponse) => void): grpcWeb.ClientReadableStream<protobuf_order_comment_order_comment_pb.ExportOrderCommentsResponse>;

  export(
    request: protobuf_order_comment_order_comment_pb.ExportOrderCommentsRequest,
    metadata: grpcWeb.Metadata | null,
    callback?: (err: grpcWeb.RpcError,
               response: protobuf_order_comment_order_comment_pb.ExportOrderCommentsResponse) => void) {
    if (callback !== undefined) {
      return this.client_.rpcCall(
        this.hostname_ +
          '/order_comment.OrderCommentService/Export',
        request,
        metadata || {},
        this.methodDescriptorExport,
        callback);
    }
    return this.client_.unaryCall(
    this.hostname_ +
      '/order_comment.OrderCommentService/Export',
    request,
    metadata || {},
    this.methodDescriptorExport);
  }

}

