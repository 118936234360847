/**
 * @fileoverview gRPC-Web generated client stub for order_message
 * @enhanceable
 * @public
 */

// Code generated by protoc-gen-grpc-web. DO NOT EDIT.
// versions:
// 	protoc-gen-grpc-web v1.4.2
// 	protoc              v3.21.12
// source: protobuf/order_message/order_message.proto


/* eslint-disable */
// @ts-nocheck


import * as grpcWeb from 'grpc-web';

import * as protobuf_order_message_order_message_pb from '../../protobuf/order_message/order_message_pb';


export class OrderMessageServiceClient {
  client_: grpcWeb.AbstractClientBase;
  hostname_: string;
  credentials_: null | { [index: string]: string; };
  options_: null | { [index: string]: any; };

  constructor (hostname: string,
               credentials?: null | { [index: string]: string; },
               options?: null | { [index: string]: any; }) {
    if (!options) options = {};
    if (!credentials) credentials = {};
    options['format'] = 'binary';

    this.client_ = new grpcWeb.GrpcWebClientBase(options);
    this.hostname_ = hostname.replace(/\/+$/, '');
    this.credentials_ = credentials;
    this.options_ = options;
  }

  methodDescriptorCreate = new grpcWeb.MethodDescriptor(
    '/order_message.OrderMessageService/Create',
    grpcWeb.MethodType.UNARY,
    protobuf_order_message_order_message_pb.CreateOrderMessageRequest,
    protobuf_order_message_order_message_pb.OrderMessageResponse,
    (request: protobuf_order_message_order_message_pb.CreateOrderMessageRequest) => {
      return request.serializeBinary();
    },
    protobuf_order_message_order_message_pb.OrderMessageResponse.deserializeBinary
  );

  create(
    request: protobuf_order_message_order_message_pb.CreateOrderMessageRequest,
    metadata: grpcWeb.Metadata | null): Promise<protobuf_order_message_order_message_pb.OrderMessageResponse>;

  create(
    request: protobuf_order_message_order_message_pb.CreateOrderMessageRequest,
    metadata: grpcWeb.Metadata | null,
    callback: (err: grpcWeb.RpcError,
               response: protobuf_order_message_order_message_pb.OrderMessageResponse) => void): grpcWeb.ClientReadableStream<protobuf_order_message_order_message_pb.OrderMessageResponse>;

  create(
    request: protobuf_order_message_order_message_pb.CreateOrderMessageRequest,
    metadata: grpcWeb.Metadata | null,
    callback?: (err: grpcWeb.RpcError,
               response: protobuf_order_message_order_message_pb.OrderMessageResponse) => void) {
    if (callback !== undefined) {
      return this.client_.rpcCall(
        this.hostname_ +
          '/order_message.OrderMessageService/Create',
        request,
        metadata || {},
        this.methodDescriptorCreate,
        callback);
    }
    return this.client_.unaryCall(
    this.hostname_ +
      '/order_message.OrderMessageService/Create',
    request,
    metadata || {},
    this.methodDescriptorCreate);
  }

  methodDescriptorUpdate = new grpcWeb.MethodDescriptor(
    '/order_message.OrderMessageService/Update',
    grpcWeb.MethodType.UNARY,
    protobuf_order_message_order_message_pb.UpdateOrderMessageRequest,
    protobuf_order_message_order_message_pb.OrderMessageResponse,
    (request: protobuf_order_message_order_message_pb.UpdateOrderMessageRequest) => {
      return request.serializeBinary();
    },
    protobuf_order_message_order_message_pb.OrderMessageResponse.deserializeBinary
  );

  update(
    request: protobuf_order_message_order_message_pb.UpdateOrderMessageRequest,
    metadata: grpcWeb.Metadata | null): Promise<protobuf_order_message_order_message_pb.OrderMessageResponse>;

  update(
    request: protobuf_order_message_order_message_pb.UpdateOrderMessageRequest,
    metadata: grpcWeb.Metadata | null,
    callback: (err: grpcWeb.RpcError,
               response: protobuf_order_message_order_message_pb.OrderMessageResponse) => void): grpcWeb.ClientReadableStream<protobuf_order_message_order_message_pb.OrderMessageResponse>;

  update(
    request: protobuf_order_message_order_message_pb.UpdateOrderMessageRequest,
    metadata: grpcWeb.Metadata | null,
    callback?: (err: grpcWeb.RpcError,
               response: protobuf_order_message_order_message_pb.OrderMessageResponse) => void) {
    if (callback !== undefined) {
      return this.client_.rpcCall(
        this.hostname_ +
          '/order_message.OrderMessageService/Update',
        request,
        metadata || {},
        this.methodDescriptorUpdate,
        callback);
    }
    return this.client_.unaryCall(
    this.hostname_ +
      '/order_message.OrderMessageService/Update',
    request,
    metadata || {},
    this.methodDescriptorUpdate);
  }

  methodDescriptorGet = new grpcWeb.MethodDescriptor(
    '/order_message.OrderMessageService/Get',
    grpcWeb.MethodType.UNARY,
    protobuf_order_message_order_message_pb.GetOrderMessagesRequest,
    protobuf_order_message_order_message_pb.GetOrderMessagesResponse,
    (request: protobuf_order_message_order_message_pb.GetOrderMessagesRequest) => {
      return request.serializeBinary();
    },
    protobuf_order_message_order_message_pb.GetOrderMessagesResponse.deserializeBinary
  );

  get(
    request: protobuf_order_message_order_message_pb.GetOrderMessagesRequest,
    metadata: grpcWeb.Metadata | null): Promise<protobuf_order_message_order_message_pb.GetOrderMessagesResponse>;

  get(
    request: protobuf_order_message_order_message_pb.GetOrderMessagesRequest,
    metadata: grpcWeb.Metadata | null,
    callback: (err: grpcWeb.RpcError,
               response: protobuf_order_message_order_message_pb.GetOrderMessagesResponse) => void): grpcWeb.ClientReadableStream<protobuf_order_message_order_message_pb.GetOrderMessagesResponse>;

  get(
    request: protobuf_order_message_order_message_pb.GetOrderMessagesRequest,
    metadata: grpcWeb.Metadata | null,
    callback?: (err: grpcWeb.RpcError,
               response: protobuf_order_message_order_message_pb.GetOrderMessagesResponse) => void) {
    if (callback !== undefined) {
      return this.client_.rpcCall(
        this.hostname_ +
          '/order_message.OrderMessageService/Get',
        request,
        metadata || {},
        this.methodDescriptorGet,
        callback);
    }
    return this.client_.unaryCall(
    this.hostname_ +
      '/order_message.OrderMessageService/Get',
    request,
    metadata || {},
    this.methodDescriptorGet);
  }

  methodDescriptorDelete = new grpcWeb.MethodDescriptor(
    '/order_message.OrderMessageService/Delete',
    grpcWeb.MethodType.UNARY,
    protobuf_order_message_order_message_pb.DeleteOrderMessagesRequest,
    protobuf_order_message_order_message_pb.DeleteOrderMessagesResponse,
    (request: protobuf_order_message_order_message_pb.DeleteOrderMessagesRequest) => {
      return request.serializeBinary();
    },
    protobuf_order_message_order_message_pb.DeleteOrderMessagesResponse.deserializeBinary
  );

  delete(
    request: protobuf_order_message_order_message_pb.DeleteOrderMessagesRequest,
    metadata: grpcWeb.Metadata | null): Promise<protobuf_order_message_order_message_pb.DeleteOrderMessagesResponse>;

  delete(
    request: protobuf_order_message_order_message_pb.DeleteOrderMessagesRequest,
    metadata: grpcWeb.Metadata | null,
    callback: (err: grpcWeb.RpcError,
               response: protobuf_order_message_order_message_pb.DeleteOrderMessagesResponse) => void): grpcWeb.ClientReadableStream<protobuf_order_message_order_message_pb.DeleteOrderMessagesResponse>;

  delete(
    request: protobuf_order_message_order_message_pb.DeleteOrderMessagesRequest,
    metadata: grpcWeb.Metadata | null,
    callback?: (err: grpcWeb.RpcError,
               response: protobuf_order_message_order_message_pb.DeleteOrderMessagesResponse) => void) {
    if (callback !== undefined) {
      return this.client_.rpcCall(
        this.hostname_ +
          '/order_message.OrderMessageService/Delete',
        request,
        metadata || {},
        this.methodDescriptorDelete,
        callback);
    }
    return this.client_.unaryCall(
    this.hostname_ +
      '/order_message.OrderMessageService/Delete',
    request,
    metadata || {},
    this.methodDescriptorDelete);
  }

  methodDescriptorList = new grpcWeb.MethodDescriptor(
    '/order_message.OrderMessageService/List',
    grpcWeb.MethodType.UNARY,
    protobuf_order_message_order_message_pb.ListOrderMessagesRequest,
    protobuf_order_message_order_message_pb.ListOrderMessagesResponse,
    (request: protobuf_order_message_order_message_pb.ListOrderMessagesRequest) => {
      return request.serializeBinary();
    },
    protobuf_order_message_order_message_pb.ListOrderMessagesResponse.deserializeBinary
  );

  list(
    request: protobuf_order_message_order_message_pb.ListOrderMessagesRequest,
    metadata: grpcWeb.Metadata | null): Promise<protobuf_order_message_order_message_pb.ListOrderMessagesResponse>;

  list(
    request: protobuf_order_message_order_message_pb.ListOrderMessagesRequest,
    metadata: grpcWeb.Metadata | null,
    callback: (err: grpcWeb.RpcError,
               response: protobuf_order_message_order_message_pb.ListOrderMessagesResponse) => void): grpcWeb.ClientReadableStream<protobuf_order_message_order_message_pb.ListOrderMessagesResponse>;

  list(
    request: protobuf_order_message_order_message_pb.ListOrderMessagesRequest,
    metadata: grpcWeb.Metadata | null,
    callback?: (err: grpcWeb.RpcError,
               response: protobuf_order_message_order_message_pb.ListOrderMessagesResponse) => void) {
    if (callback !== undefined) {
      return this.client_.rpcCall(
        this.hostname_ +
          '/order_message.OrderMessageService/List',
        request,
        metadata || {},
        this.methodDescriptorList,
        callback);
    }
    return this.client_.unaryCall(
    this.hostname_ +
      '/order_message.OrderMessageService/List',
    request,
    metadata || {},
    this.methodDescriptorList);
  }

  methodDescriptorListAfter = new grpcWeb.MethodDescriptor(
    '/order_message.OrderMessageService/ListAfter',
    grpcWeb.MethodType.UNARY,
    protobuf_order_message_order_message_pb.ListAfterOrderMessagesRequest,
    protobuf_order_message_order_message_pb.ListAfterOrderMessagesResponse,
    (request: protobuf_order_message_order_message_pb.ListAfterOrderMessagesRequest) => {
      return request.serializeBinary();
    },
    protobuf_order_message_order_message_pb.ListAfterOrderMessagesResponse.deserializeBinary
  );

  listAfter(
    request: protobuf_order_message_order_message_pb.ListAfterOrderMessagesRequest,
    metadata: grpcWeb.Metadata | null): Promise<protobuf_order_message_order_message_pb.ListAfterOrderMessagesResponse>;

  listAfter(
    request: protobuf_order_message_order_message_pb.ListAfterOrderMessagesRequest,
    metadata: grpcWeb.Metadata | null,
    callback: (err: grpcWeb.RpcError,
               response: protobuf_order_message_order_message_pb.ListAfterOrderMessagesResponse) => void): grpcWeb.ClientReadableStream<protobuf_order_message_order_message_pb.ListAfterOrderMessagesResponse>;

  listAfter(
    request: protobuf_order_message_order_message_pb.ListAfterOrderMessagesRequest,
    metadata: grpcWeb.Metadata | null,
    callback?: (err: grpcWeb.RpcError,
               response: protobuf_order_message_order_message_pb.ListAfterOrderMessagesResponse) => void) {
    if (callback !== undefined) {
      return this.client_.rpcCall(
        this.hostname_ +
          '/order_message.OrderMessageService/ListAfter',
        request,
        metadata || {},
        this.methodDescriptorListAfter,
        callback);
    }
    return this.client_.unaryCall(
    this.hostname_ +
      '/order_message.OrderMessageService/ListAfter',
    request,
    metadata || {},
    this.methodDescriptorListAfter);
  }

  methodDescriptorGetLastMessage = new grpcWeb.MethodDescriptor(
    '/order_message.OrderMessageService/GetLastMessage',
    grpcWeb.MethodType.UNARY,
    protobuf_order_message_order_message_pb.GetLastMessageRequest,
    protobuf_order_message_order_message_pb.GetLastMessageResponse,
    (request: protobuf_order_message_order_message_pb.GetLastMessageRequest) => {
      return request.serializeBinary();
    },
    protobuf_order_message_order_message_pb.GetLastMessageResponse.deserializeBinary
  );

  getLastMessage(
    request: protobuf_order_message_order_message_pb.GetLastMessageRequest,
    metadata: grpcWeb.Metadata | null): Promise<protobuf_order_message_order_message_pb.GetLastMessageResponse>;

  getLastMessage(
    request: protobuf_order_message_order_message_pb.GetLastMessageRequest,
    metadata: grpcWeb.Metadata | null,
    callback: (err: grpcWeb.RpcError,
               response: protobuf_order_message_order_message_pb.GetLastMessageResponse) => void): grpcWeb.ClientReadableStream<protobuf_order_message_order_message_pb.GetLastMessageResponse>;

  getLastMessage(
    request: protobuf_order_message_order_message_pb.GetLastMessageRequest,
    metadata: grpcWeb.Metadata | null,
    callback?: (err: grpcWeb.RpcError,
               response: protobuf_order_message_order_message_pb.GetLastMessageResponse) => void) {
    if (callback !== undefined) {
      return this.client_.rpcCall(
        this.hostname_ +
          '/order_message.OrderMessageService/GetLastMessage',
        request,
        metadata || {},
        this.methodDescriptorGetLastMessage,
        callback);
    }
    return this.client_.unaryCall(
    this.hostname_ +
      '/order_message.OrderMessageService/GetLastMessage',
    request,
    metadata || {},
    this.methodDescriptorGetLastMessage);
  }

  methodDescriptorExport = new grpcWeb.MethodDescriptor(
    '/order_message.OrderMessageService/Export',
    grpcWeb.MethodType.UNARY,
    protobuf_order_message_order_message_pb.ExportOrderMessagesRequest,
    protobuf_order_message_order_message_pb.ExportOrderMessagesResponse,
    (request: protobuf_order_message_order_message_pb.ExportOrderMessagesRequest) => {
      return request.serializeBinary();
    },
    protobuf_order_message_order_message_pb.ExportOrderMessagesResponse.deserializeBinary
  );

  export(
    request: protobuf_order_message_order_message_pb.ExportOrderMessagesRequest,
    metadata: grpcWeb.Metadata | null): Promise<protobuf_order_message_order_message_pb.ExportOrderMessagesResponse>;

  export(
    request: protobuf_order_message_order_message_pb.ExportOrderMessagesRequest,
    metadata: grpcWeb.Metadata | null,
    callback: (err: grpcWeb.RpcError,
               response: protobuf_order_message_order_message_pb.ExportOrderMessagesResponse) => void): grpcWeb.ClientReadableStream<protobuf_order_message_order_message_pb.ExportOrderMessagesResponse>;

  export(
    request: protobuf_order_message_order_message_pb.ExportOrderMessagesRequest,
    metadata: grpcWeb.Metadata | null,
    callback?: (err: grpcWeb.RpcError,
               response: protobuf_order_message_order_message_pb.ExportOrderMessagesResponse) => void) {
    if (callback !== undefined) {
      return this.client_.rpcCall(
        this.hostname_ +
          '/order_message.OrderMessageService/Export',
        request,
        metadata || {},
        this.methodDescriptorExport,
        callback);
    }
    return this.client_.unaryCall(
    this.hostname_ +
      '/order_message.OrderMessageService/Export',
    request,
    metadata || {},
    this.methodDescriptorExport);
  }

  methodDescriptorGetNew = new grpcWeb.MethodDescriptor(
    '/order_message.OrderMessageService/GetNew',
    grpcWeb.MethodType.UNARY,
    protobuf_order_message_order_message_pb.GetNewOrderMessagesRequest,
    protobuf_order_message_order_message_pb.GetNewOrderMessagesResponse,
    (request: protobuf_order_message_order_message_pb.GetNewOrderMessagesRequest) => {
      return request.serializeBinary();
    },
    protobuf_order_message_order_message_pb.GetNewOrderMessagesResponse.deserializeBinary
  );

  getNew(
    request: protobuf_order_message_order_message_pb.GetNewOrderMessagesRequest,
    metadata: grpcWeb.Metadata | null): Promise<protobuf_order_message_order_message_pb.GetNewOrderMessagesResponse>;

  getNew(
    request: protobuf_order_message_order_message_pb.GetNewOrderMessagesRequest,
    metadata: grpcWeb.Metadata | null,
    callback: (err: grpcWeb.RpcError,
               response: protobuf_order_message_order_message_pb.GetNewOrderMessagesResponse) => void): grpcWeb.ClientReadableStream<protobuf_order_message_order_message_pb.GetNewOrderMessagesResponse>;

  getNew(
    request: protobuf_order_message_order_message_pb.GetNewOrderMessagesRequest,
    metadata: grpcWeb.Metadata | null,
    callback?: (err: grpcWeb.RpcError,
               response: protobuf_order_message_order_message_pb.GetNewOrderMessagesResponse) => void) {
    if (callback !== undefined) {
      return this.client_.rpcCall(
        this.hostname_ +
          '/order_message.OrderMessageService/GetNew',
        request,
        metadata || {},
        this.methodDescriptorGetNew,
        callback);
    }
    return this.client_.unaryCall(
    this.hostname_ +
      '/order_message.OrderMessageService/GetNew',
    request,
    metadata || {},
    this.methodDescriptorGetNew);
  }

  methodDescriptorSetLastView = new grpcWeb.MethodDescriptor(
    '/order_message.OrderMessageService/SetLastView',
    grpcWeb.MethodType.UNARY,
    protobuf_order_message_order_message_pb.SetLastViewOrderMessagesRequest,
    protobuf_order_message_order_message_pb.SetLastViewOrderMessagesResponse,
    (request: protobuf_order_message_order_message_pb.SetLastViewOrderMessagesRequest) => {
      return request.serializeBinary();
    },
    protobuf_order_message_order_message_pb.SetLastViewOrderMessagesResponse.deserializeBinary
  );

  setLastView(
    request: protobuf_order_message_order_message_pb.SetLastViewOrderMessagesRequest,
    metadata: grpcWeb.Metadata | null): Promise<protobuf_order_message_order_message_pb.SetLastViewOrderMessagesResponse>;

  setLastView(
    request: protobuf_order_message_order_message_pb.SetLastViewOrderMessagesRequest,
    metadata: grpcWeb.Metadata | null,
    callback: (err: grpcWeb.RpcError,
               response: protobuf_order_message_order_message_pb.SetLastViewOrderMessagesResponse) => void): grpcWeb.ClientReadableStream<protobuf_order_message_order_message_pb.SetLastViewOrderMessagesResponse>;

  setLastView(
    request: protobuf_order_message_order_message_pb.SetLastViewOrderMessagesRequest,
    metadata: grpcWeb.Metadata | null,
    callback?: (err: grpcWeb.RpcError,
               response: protobuf_order_message_order_message_pb.SetLastViewOrderMessagesResponse) => void) {
    if (callback !== undefined) {
      return this.client_.rpcCall(
        this.hostname_ +
          '/order_message.OrderMessageService/SetLastView',
        request,
        metadata || {},
        this.methodDescriptorSetLastView,
        callback);
    }
    return this.client_.unaryCall(
    this.hostname_ +
      '/order_message.OrderMessageService/SetLastView',
    request,
    metadata || {},
    this.methodDescriptorSetLastView);
  }

  methodDescriptorGetLastView = new grpcWeb.MethodDescriptor(
    '/order_message.OrderMessageService/GetLastView',
    grpcWeb.MethodType.UNARY,
    protobuf_order_message_order_message_pb.GetLastViewOrderMessagesRequest,
    protobuf_order_message_order_message_pb.GetLastViewOrderMessagesResponse,
    (request: protobuf_order_message_order_message_pb.GetLastViewOrderMessagesRequest) => {
      return request.serializeBinary();
    },
    protobuf_order_message_order_message_pb.GetLastViewOrderMessagesResponse.deserializeBinary
  );

  getLastView(
    request: protobuf_order_message_order_message_pb.GetLastViewOrderMessagesRequest,
    metadata: grpcWeb.Metadata | null): Promise<protobuf_order_message_order_message_pb.GetLastViewOrderMessagesResponse>;

  getLastView(
    request: protobuf_order_message_order_message_pb.GetLastViewOrderMessagesRequest,
    metadata: grpcWeb.Metadata | null,
    callback: (err: grpcWeb.RpcError,
               response: protobuf_order_message_order_message_pb.GetLastViewOrderMessagesResponse) => void): grpcWeb.ClientReadableStream<protobuf_order_message_order_message_pb.GetLastViewOrderMessagesResponse>;

  getLastView(
    request: protobuf_order_message_order_message_pb.GetLastViewOrderMessagesRequest,
    metadata: grpcWeb.Metadata | null,
    callback?: (err: grpcWeb.RpcError,
               response: protobuf_order_message_order_message_pb.GetLastViewOrderMessagesResponse) => void) {
    if (callback !== undefined) {
      return this.client_.rpcCall(
        this.hostname_ +
          '/order_message.OrderMessageService/GetLastView',
        request,
        metadata || {},
        this.methodDescriptorGetLastView,
        callback);
    }
    return this.client_.unaryCall(
    this.hostname_ +
      '/order_message.OrderMessageService/GetLastView',
    request,
    metadata || {},
    this.methodDescriptorGetLastView);
  }

}

